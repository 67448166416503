<template>
  <div>
    <div class="content" @click='showtype=false'>
      <div class="all">
      
        <div class="table">
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
             
            :stripe="true"
            class="table-height"
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                :index="(parseInt(search.current)-1)*parseInt(search.size)+1"
                >
                </el-table-column>
            <el-table-column
              prop="remark"
              label="简要描述"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="template"
              label="消息模板"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
             <el-table-column
              prop="receiver"
              label="接收人"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            
           
            <el-table-column
              prop="statusName"
              label="状态"
              align="center"
              show-overflow-tooltip
              sortable="custom"
             
            >
            </el-table-column>
          
            <el-table-column prop="createName" label="创建人" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="200">
              <template slot-scope="{ row }">
                <el-button type="text" size="small" @click="openDia(row)" >编辑</el-button>
                <el-button type="text" size="small" @click="chageStatus(row)"  >{{row.statusCode==1?'暂停':'开启'}}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
          <el-pagination
            :current-page="search.current"
            :background="true"
            :page-sizes="[30, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="setFrom('form')"
      >
      <el-form ref="form" :model="form" label-width="150px" size="mini" class="form">
        
        <el-form-item label="简要描述:">
          <el-input v-model="form.remark" class="input"></el-input>
        </el-form-item>
       
        <el-form-item label="接收人">
          <el-radio-group v-model="form.receiverTypeCode" @change="receiverType">
          <div>
            <el-radio label="1" v-if="form.receiverTypeCode!=2">按岗位设置</el-radio>
            <el-select v-model="form.receiverRoleId" v-if="form.receiverTypeCode!=2" multiple placeholder="请选择">
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-radio  label="2" v-if="form.receiverTypeCode==2">特定执行人</el-radio>
          </div>
          <div>
            <el-radio  label="3" v-if="form.receiverTypeCode!=2">固定接收人</el-radio>
             <el-select v-if="form.receiverTypeCode!=2" v-model="form.receiverUserId" multiple placeholder="请选择">
              <el-option
                v-for="item of userList"
                :key="item.userId+''"
                :label="item.realname"
                :value="item.userId+''">
              </el-option>
            </el-select>
          </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="消息实例:">
          <el-input
          disabled
            type="textarea"
            class="input"
            :rows="4"
            placeholder="请输入内容"
            v-model="form.template">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="saveadd" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {selectDictListByParentCode } from '@/apis/commonType'
import {bzMessageConfig_pageMessageTemplate,bzMessageConfig_getTemplateDetail,bzMessageConfig_saveMessageTemplate,bzMessageConfig_setTemplateStatus,role_selectRoleList,user_selectUserDeptPageList} from '../../RequestPort/settings/settings'
export default {
  name: 'settings',

  data() {
    return {
      total: 10,
      search: { current: 1, size: 100},
      tableData:[],
      dialogVisible:false,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      roleList:[],
      userList:[],
      textarea:''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    openDia(row){
      this.dialogVisible = true
      bzMessageConfig_getTemplateDetail({id:row.id}).then(res=>{
        this.form = res.data;

        this.form.receiverTypeCode=this.form.receiverTypeCode+'';
        if(res.data.receiverRoleId){
            this.form.receiverRoleId = res.data.receiverRoleId.split(',') 
        }else{
          this.form.receiverRoleId = []
        }
        if( res.data.receiverUserId){
          this.form.receiverUserId = res.data.receiverUserId.split(',') 
        }else{
          this.form.receiverUserId = []
        }
      })
      role_selectRoleList({}).then(res=>{
        this.roleList = res.data;
      })
      user_selectUserDeptPageList({current:1,size:100000}).then(res=>{
        this.userList = res.data.records
      })
    },
    handleSizeChange(val) {
      this.search.size = val
      this.getList(1)
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    },
    getList(current) {
      if (current) {
        this.search.current = 1
      }
      //查列表
      bzMessageConfig_pageMessageTemplate(this.search).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    setFrom(){
      this.form={
        id:'',
        receiverRoleId:'',
        receiverTypeCode:'',
        receiverUserId:'',
        remark:'',
        template:''
      }
    },
    receiverType(val){
        if(val==1){
          this.form.receiverUserId =[]
        }
        if(val==2){
          this.form.receiverRoleId =[]
          this.form.receiverUserId =[]
        }
        if(val==3){
          this.form.receiverRoleId =[]
        }
    },
    saveadd(){
      this.form.receiverRoleId = this.form.receiverRoleId.join(',') 
      this.form.receiverUserId = this.form.receiverUserId.join(',') 
      bzMessageConfig_saveMessageTemplate(this.form).then(ret=>{
        if(ret.code==200){
          this.$message.success('编辑成功！')
          this.setFrom();
          this.dialogVisible = false;
          this.getList();
        }else{
          this.$message.error(ret.msg)
        }
      })
  },
  chageStatus(row){
    const showNmae = row.statusCode==1?'暂停':'开启'
    bzMessageConfig_setTemplateStatus({id:row.id,statusCode:row.statusCode==1?0:1}).then(ret=>{
       if(ret.code==200){
          this.$message.success(showNmae+'成功！')
          this.getList();
        }else{
          this.$message.error(ret.msg)
        }
    })
  }
  }
}
</script>

<style  lang="less" scoped>
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchOptions{
  width: 150px;
}
.SearchOptions2{
  width: 100px;
}
.SearchDate{
  width: 300px;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
   background-color: #ffffff;
   height: auto;
}
.table-height{height: calc(100vh - 270px);}
.all {
  background-color:#f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 1%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
.input{
  width: 318px;
}
.form{
  margin-left:3%;
}
</style>
